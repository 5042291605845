import React, { } from 'react';
import { Spin, Tabs, Space, Tag, Button, message, Modal, } from 'antd';
import api, { I18n, ReplenishRequestInventory, GetReplenishRequestDetailParameters, GetReplenishRequestDetailRes, GetReplenishRequestDetailDataRes, GetReplenishRequestCancelParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import { ActionUserRes, initData } from '../../actions/ActionUser';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

interface PageState {
  isSpinLoading: boolean
  replenishRequestDetail?: GetReplenishRequestDetailRes
}
interface Props {
  kioskId: string
  date: string
}



type TableRes = ReplenishRequestInventory & { id: string }
interface Page {
  tableRef?: JJ_Table<TableRes> | null
}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ReplenishRequestDetailList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
    }
  }
  tableRef?: JJ_Table<TableRes> | null
  componentDidMount() {
    this._initData()
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      const replenishRequestDetail = await this._getReplenishRequestDetail({ kioskId: this.props.kioskId, date: this.props.date })

      if (replenishRequestDetail.data.length === 0) {

      }


      this.setState({
        isSpinLoading: false,
        replenishRequestDetail: replenishRequestDetail,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _getReplenishRequestDetail = async (params: GetReplenishRequestDetailParameters) => {
    const res = await api.GetReplenishRequestDetail(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postReplenishRequestCancel = async (params: GetReplenishRequestCancelParameters) => {
    const res = await api.PostReplenishRequestCancel(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _tableView = (tableDatas: TableRes[]) => {
    return (
      <JJ_Table<TableRes, {
        createRange?: [moment.Moment, moment.Moment] | null
      }>

        ref={ref => this.tableRef = ref}
        tableProps={{
          pagination: false,
        }}
        columns={
          [
            {
              title: '產品名',
              dataIndex: 'product.title',
              key: 'product.title',
              width: 180,
              render: (value, record, index) => {
                if (record.product) {
                  return (record.product.title as I18n)[this.props.user.data.language]
                }
              }
            },
            {
              title: '產品唯一码',
              dataIndex: 'product.uniqueCode',
              key: 'product.uniqueCode',
              width: 180,
              render: (value, record, index) => {
                if (record.product) {
                  return record.product.uniqueCode
                }
              }
            },
            {
              title: '總數量',
              dataIndex: 'record.amount',
              key: 'record.amount',
              width: 180,
              render: (value, record, index) => {
                return record.amount
              }
            },
          ]}
        onDataSource={async (body) => {
          const { sourceItemBody } = body
          const params: {
          } = {}
          return { totalCount: tableDatas.length, data: tableDatas }
        }}
      />
    )
  }

  _getStateText = (state: GetReplenishRequestDetailDataRes['state']) => {
    switch (state) {
      case 'CANCELLED': {
        return <Tag color='warning'>已取消</Tag>
      }
      case 'COMPLETED': {
        return <Tag color='success'>已完成</Tag>
      }
      case 'DELIVERING': {
        return <Tag color='warning'>已提交</Tag>
      }
      case 'PACKED': {
        return <Tag color='warning'>已打包</Tag>
      }
      case 'PENDING': {
        return <Tag color='warning'>待定</Tag>
      }
    }
  }


  render() {
    return (
      <div id="ReplenishRequestDetailList"
      >
        <Spin spinning={this.state.isSpinLoading}>
          <Tabs type="card">
            {this.state.replenishRequestDetail?.data.map((detail, index) => {
              return (
                <Tabs.TabPane
                  tab={(
                    <Space>
                      <span>{detail.id}</span>
                      {this._getStateText(detail.state)}
                      <Button
                        onClick={async () => {

                          confirm({
                            title: `是否取消${detail.id}補貨單？`,
                            icon: <ExclamationCircleOutlined />,
                            okText: 'Yes',
                            okType: 'danger',
                            cancelText: 'No',
                            onOk: async () => {
                              try {
                                this.setState({
                                  isSpinLoading: true
                                })
                                await this._postReplenishRequestCancel({ id: detail.id })
                                this.setState({
                                  isSpinLoading: false
                                })
                                message.success(`取消成功`)

                                await this._initData()
                              } catch (error) {
                                console.log(error)
                                message.success(`取消失敗`)
                                this.setState({
                                  isSpinLoading: false
                                })
                              }
                            },
                            onCancel() {
                            },
                          })
                        }}
                        disabled={!['PENDING', 'DELIVERING'].some(key => key === detail.state)}
                        type="dashed" size={'small'}>
                        取消
                        </Button>
                    </Space>
                  )}
                  key={index} >
                  {this._tableView(detail.replenishRequestInventories.map((item, index) => ({ ...item, id: `${index}` })))}
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </Spin>

      </div >
    )
  }

}
export default connector(ReplenishRequestDetailList)



