import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetCommunityRes, GetAreaListParameters, GetCommunityListParameters, GetAreaRes, GetPartnerCompanyRes, GetPartnerCompanyListParameters, GetKioskVendingListParameters, GetKioskVendingRes } from '../../api';
import { Select, Tag, Space } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';

interface PageState {
  companies: GetCommunityRes[]
  partnerCompanies: GetPartnerCompanyRes[]
  kioskVendings: GetKioskVendingRes[]
}

type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class KioskVendingEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      companies: [],
      partnerCompanies: [],
      kioskVendings: [],
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', values)
    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutKioskVending({
          ...values,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'add': {

        const res = await api.PostKioskVending({
          ...values,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
    }
  }

  _getCommunityList = async (params: GetCommunityListParameters) => {
    const res = await api.GetCommunityList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data

  }
  _getPartnerCompanyList = async (params: GetPartnerCompanyListParameters) => {
    const res = await api.GetPartnerCompanyList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _onLoadData = async (): Promise<any> => {

    const communityList = await this._getCommunityList({ count: 10000, scope: ['area'] })
    const partnerCompanyList = await this._getPartnerCompanyList({ count: 10000, })
    this.setState({
      companies: communityList.data,
      partnerCompanies: partnerCompanyList.data,
    })

    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetKioskVending({ id: this.props.id, lang: true })

        if (res.kind === 'ok') {
          return {
            ...res.data,
            communityId: res.data.community,
            partnerCompanyId: res.data.partnerCompany,
          }
        }
        throw Error(res.kind)
      }
      case 'add': {

        return{
          isEnable:true
        }
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'communityId',
            label: '社區',
            rules: [
              {
                required: true,
                message: `請選擇社區`
              }
            ],
            componet: (
              <Select>
                {this.state.companies.map(community => {
                  const area = community.area as GetAreaRes
                  if (area) {
                    return (
                      <Select.Option key={community.id} value={community.id}  >
                        <Space>
                          {community.name}
                          <Tag color={area.status === 'NORMAL' ? 'blue' : 'red'}>{area.status === 'NORMAL' ? '正常' : '測試'}</Tag>
                          <Tag color={'pink'}>{community.code}</Tag>
                        </Space>
                      </Select.Option>
                    )
                  }

                })}
              </Select>
            ),
          },
          {
            id: 'partnerCompanyId',
            label: '合作機構',
            rules: [
              {
                required: true,
                message: `請選擇合作機構`
              }
            ],
            componet: (
              <Select>
                {this.state.partnerCompanies.map(partnerCompany => {
                  return (
                    <Select.Option
                      key={partnerCompany.id}
                      value={partnerCompany.id}
                    >
                      <Space>
                        {partnerCompany.name}
                        <Tag color={'pink'}>{partnerCompany.code}</Tag>
                      </Space>
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'kioskId',
            label: '機器ID',
            rules: [
              {
                required: true,
                message: `請輸入機器ID`,
              }
            ]
          },
          {
            id: 'i18nText',
            name: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'i18nText',
            name: 'address',
            label: '地址',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'code',
            label: 'code【補全數據後移除】',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'communityId',
            label: '社區',
            rules: [
              {
                required: true,
                message: `請選擇社區`
              }
            ],
            componet: (
              <Select>
                {this.state.companies.map(community => {
                  const area = community.area as GetAreaRes
                  if (area) {
                    return (
                      <Select.Option key={community.id} value={community.id}  >
                        <Space>
                          {community.name}
                          <Tag color={area.status === 'NORMAL' ? 'blue' : 'red'}>{area.status === 'NORMAL' ? '正常' : '測試'}</Tag>
                          <Tag color={'pink'}>{community.code}</Tag>
                        </Space>
                      </Select.Option>
                    )
                  }
                })}
              </Select>
            ),
          },
          {
            id: 'partnerCompanyId',
            label: '合作機構',
            rules: [
              {
                required: true,
                message: `請選擇合作機構`
              }
            ],
            componet: (
              <Select>
                {this.state.partnerCompanies.map(partnerCompany => {
                  return (
                    <Select.Option key={partnerCompany.id} value={partnerCompany.id}  >
                      <Space>
                        {partnerCompany.name}
                        <Tag color={'pink'}>{partnerCompany.code}</Tag>
                      </Space>
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'kioskId',
            label: '機器ID',
            rules: [
              {
                required: true,
                message: `請輸入機器ID`,
              }
            ]
          },
          {
            id: 'kioskNumber',
            label: '售賣機编號',
            rules: [
              {
                required: true,
                message: `請選擇售賣機编號`,
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.value) {
                    return String(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {[...Array(99)]
                  .map((item, index) => index + 1)
                  .filter((item) => {
                    return !this.state.kioskVendings.some(kioskVending => {
                      const code = kioskVending.code
                      const number = Number(code.split('-')[1])
                      return number === item
                    })
                  })
                  .map((item, index) => {
                    return (
                      <Select.Option
                        key={item}
                        value={item}  >
                        <Space>
                          {item}
                        </Space>
                      </Select.Option>
                    )
                  })
                }
              </Select>
            ),
          },
          {
            id: 'i18nText',
            name: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'i18nText',
            name: 'address',
            label: '地址',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }





  render() {
    return (
      <div id="KioskVendingEditAndAdd">
        <JJ_From<{
          communityId?: string
          partnerCompanyId?: string
          kioskNumber?: number
        }>
          formRef={res => this.formRef = res}
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
          formProps={{
            onValuesChange: async (value, values) => {
              if (this.props.type === 'add') {
                const { communityId, partnerCompanyId } = values
                if (communityId && partnerCompanyId) {
                  if ((communityId !== this.communityId) || (partnerCompanyId !== this.partnerCompanyId)) {
                    this.partnerCompanyId = partnerCompanyId
                    this.communityId = communityId
                    this.formRef && this.formRef.setFieldsValue({ kioskNumber: undefined })
                    try {
                      const kioskVendingList = await this._getKioskVendingList({
                        communityId: this.communityId,
                        partnerCompanyId: this.partnerCompanyId,
                        count: 1000,
                      })
                      this.setState({
                        kioskVendings: kioskVendingList.data,
                      })
                    } catch (error) {
                      console.log(error)
                    }

                  }
                }
              }
            }
          }}
        />
      </div>
    )
  }
  communityId: string | undefined
  partnerCompanyId: string | undefined
  formRef: (
    FormInstance<{
      communityId?: string | undefined;
      partnerCompanyId?: string | undefined;
      kioskNumber?: number | undefined;
    }> | null
  ) | undefined
}
export default connector(KioskVendingEditAndAdd)

