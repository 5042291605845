import { AllErrorCode } from './api-error-code-type'

export const getApiErrorCodeText = (errorCode: AllErrorCode) => {
    switch (errorCode) {
        case 'AREA_NOT_FOUND': {
            return '找不到區域'
        }
        // case 'AUTH_NOT_FOUND': {
        //     return ''
        // }
        case 'COMMUNITY_NOT_FOUND': {
            return '找不到社區'
        }
        case 'DUPLICATE_CODE': {
            return '不可存在重覆代號'
        }
        case 'DUPLICATE_FOUND_CARD_CODE': {
            return '不可存在重覆食品卡號'
        }
        case 'DUPLICATE_KIOSK': {
            return '不可存在重覆 Kiosk ID'
        }
        case 'DUPLICATE_NAME': {
            return '不可存在重覆名稱'
        }
        case 'DUPLICATE_PHONE': {
            return '不可存在重覆電話號碼'
        }
        case 'DUPLICATE_USERNAME': {
            return '不可存在重覆用戶名'
        }
        case 'EXTERNAL_ID_DUPLICATE': {
            return '不可存在重覆八達通卡號'
        }
        case 'FOOD_CARD_CODE_DUPLICATE': {
            return '不可存在重覆食品卡號'
        }
        // case 'FOOD_CARD_CODE_EXIST': {
        //     return ''
        // }
        case 'FOOD_CARD_NOT_FOUND': {
            return '找不到食品卡號'
        }
        case 'FOOD_CARD_USER_MEMBER_FAMILY_NOT_FOUND': {
            return '找不到用戶家庭'
        }
        case 'FORMAT_ERROR': {
            return '文字格式不正確'
        }
        case 'INCORRECT_PASSWORD': {
            return '密碼不正確'
        }
        // case 'INVALID_FOOD_CARD_USER_MEMBER': {
        //     return ''
        // }
        case 'INVALID_FOOD_CARD_USER_MEMBER_FAMILY': {
            return '找不到用戶家庭'
        }
        case 'INVALID_KIOSK_VENDING': {
            return '找不到售賣機'
        }
        case 'INVALID_PERMISSION': {
            return '沒有操作權限'
        }
        // case 'INVALID_PHONE': {
        //     return ''
        // }
        case 'INVALID_SURVEY': {
            return '找不到問卷調查'
        }
        case 'INVALID_SURVEY_SUBJECT': {
            return '找不到問卷調查題目'
        }
        case 'INVALID_USER': {
            return '找不到用戶'
        }
        // case 'JPUSH_SERVICE_ERROR': {
        //     return ''
        // }
        // case 'MEMBER_CARD_EXISTS': {
        //     return ''
        // }
        // case 'NOT_FAMILY_CARD': {
        //     return ''
        // }
        case 'NOT_FOUND': {
            return '找不到記錄'
        }
        case 'NOT_FOUND_COMMUNITY': {
            return '找不到社區'
        }
        case 'NOT_FOUND_FOOD_CARD': {
            return '找不到食品卡'
        }
        case 'NOT_FOUND_KIOSK_VENDING': {
            return '找不到售賣機'
        }
        case 'NOT_FOUND_PARTNER_COMPANY': {
            return '找不到合作機構'
        }
        case 'NOT_FOUND_USER_AUTH': {
            return '找不到家庭帳號'
        }
        case 'PARTNER_COMPANY_NOT_FOUND': {
            return '找不到合作機構'
        }
        case 'USED_AT_DUPLICATE': {
            return '不可存在重覆日期的餐單'
        }
        case 'DUPLICATE_RENEWAL': {
            return '改家庭已經續期中'
        }
        case 'STATUS_ERROR': {
            return '續期狀態錯誤'
        }
        case 'STATE_CANCELLED': {
            return '此補貨單已取消過，請勿重複取消'
        }
        case 'STATE_COMPLETED': {
            return '此補貨單已完成，不能取消'
        }

        default: {
            return `系統錯誤，請聯繫開發管理人員 (${errorCode})`
        }
    }
}