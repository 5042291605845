import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetFoodCardUserMemberParameters, PutFoodCardUserMemberParameters, PostFoodCardUserMemberParameters, GetKioskVendingRes, GetKioskVendingListParameters, GetQuotaListParameters, GetQuotaRes } from '../../api';
import { Select, Tag, DatePicker, Space } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import TextArea from 'antd/lib/input/TextArea';
import { ActionUserRes } from '../../actions/ActionUser';
import { getI18nText } from '../../public';


interface PageState {
  kioskVendings: GetKioskVendingRes[]
  quotas: GetQuotaRes[]
}

export type FoodCardUserMemberEditAndAddPropsType = { type: 'add', foodCardUserMemberFamilyId: string } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
  user: ActionUserRes['data']
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & FoodCardUserMemberEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardUserMemberEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      kioskVendings: [],
      quotas: []
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)



    switch (this.props.type) {
      case 'edit': {
        const res = await this._putFoodCardUserMember({
          ...values,
          id: this.props.id,
          yearOfBirth: values.yearOfBirth ? new Date(values.yearOfBirth).toISOString() : undefined,
        })

        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {

        const res = await this._postFoodCardUserMember({
          ...values,
          phone: `+852-${values.phone}`,
          yearOfBirth: values.yearOfBirth ? new Date(values.yearOfBirth).toISOString() : undefined,
          foodCardUserMemberFamilyId: this.props.foodCardUserMemberFamilyId,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {

    const quotaList = await this._getQuotaList({ count: 1000 })

    this.setState({
      quotas: quotaList.data
    })

    switch (this.props.type) {
      case 'edit': {
        const res = await this._getFoodCardUserMember({ id: this.props.id })

        const yearOfBirth = res.yearOfBirth ? moment(res.yearOfBirth) : res.yearOfBirth



        return {
          ...res,
          yearOfBirth,
        }
      }
      case 'add': {

        const kioskVendingList = await this._getKioskVendingList({ count: 10000 })

        this.setState({
          kioskVendings: kioskVendingList.data
        })

        return {
          status: 'NORMAL',
        }
      }


    }
  };

  _getQuotaList = async (param: GetQuotaListParameters) => {
    const res = await api.GetQuotaList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getFoodCardUserMember = async (param: GetFoodCardUserMemberParameters) => {
    const res = await api.GetFoodCardUserMember(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putFoodCardUserMember = async (param: PutFoodCardUserMemberParameters) => {
    const res = await api.PutFoodCardUserMember(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postFoodCardUserMember = async (param: PostFoodCardUserMemberParameters) => {
    const res = await api.PostFoodCardUserMember(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }



  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'faCode',
            label: 'FA 號碼',
            rules: [
              {
                required: true,
                message: `請輸入 FA 號碼`
              }
            ],
          },
          {
            id: 'name',
            label: '姓名',
            rules: [
              {
                required: false,
                message: `請輸入 姓名`
              }
            ],
          },
          {
            id: 'gender',
            label: '性別',
            rules: [
              {
                required: false,
                message: `請輸入 性別`
              }
            ],
            componet: (
              <Select
                allowClear={true}
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.key && typeof (option.key) === 'string') {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {
                  [
                    { key: 'F', color: 'pink', title: '女' },
                    { key: 'M', color: 'blue', title: '男' },
                  ].map(item => {
                    return (
                      <Select.Option
                        key={item.key}
                        value={item.key}
                      >
                        <Tag
                          color={item.color}
                        >
                          {item.title}
                        </Tag>
                      </Select.Option>
                    )
                  })
                }

              </Select>
            ),
          },
          {
            id: 'status',
            label: '狀態',
            rules: [
              {
                required: true,
                message: `請選擇 狀態`
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.key && typeof (option.key) === 'string') {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >

                {
                  [
                    { key: 'NORMAL', title: '正常', color: 'green' },
                    { key: 'EXEMPTED', title: '豁免', color: 'blue' },
                    { key: 'QUIT_SERVICE_DURING_WAITING', title: '輪候時已退出', color: 'red' },
                  ].map(item => {
                    return (
                      <Select.Option
                        key={item.key}
                        value={item.key}
                      >
                        <Tag
                          color={item.color}
                        >
                          {item.title}
                        </Tag>
                      </Select.Option>
                    )
                  })
                }

              </Select>
            ),
          },
          {
            id: 'yearOfBirth',
            label: '出身年份',
            rules: [
              {
                required: false,
                message: `請選擇 出身年份`
              }
            ],
            componet: (
              <DatePicker picker='year' />
            )
          },
          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            )
          },

        

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'faCode',
            label: 'FA 號碼',
            rules: [
              {
                required: true,
                message: `請輸入 FA 號碼`
              }
            ],
          },
          {
            id: 'name',
            label: '姓名',
            rules: [
              {
                required: true,
                message: `請輸入 姓名`
              }
            ],
          },
          {
            id: 'gender',
            label: '性別',
            rules: [
              {
                required: false,
                message: `請輸入 性別`
              }
            ],
            componet: (
              <Select
                allowClear={true}
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.key && typeof (option.key) === 'string') {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {
                  [
                    { key: 'F', color: 'pink', title: '女' },
                    { key: 'M', color: 'blue', title: '男' },
                  ].map(item => {
                    return (
                      <Select.Option
                        key={item.key}
                        value={item.key}
                      >
                        <Tag
                          color={item.color}
                        >
                          {item.title}
                        </Tag>
                      </Select.Option>
                    )
                  })
                }

              </Select>
            ),
          },
          {
            id: 'status',
            label: '狀態',
            rules: [
              {
                required: true,
                message: `請選擇 狀態`
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.key && typeof (option.key) === 'string') {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {
                  [
                    { key: 'NORMAL', title: '正常', color: 'green' },
                    { key: 'EXEMPTED', title: '豁免', color: 'blue' },
                    { key: 'REFERRAL', title: '轉介中', color: 'purple' },
                    { key: 'QUIT_SERVICE_DURING_WAITING', title: '輪候時已退出', color: 'red' },
                  ].map(item => {
                    return (
                      <Select.Option
                        key={item.key}
                        value={item.key}
                      >
                        <Tag
                          color={item.color}
                        >
                          {item.title}
                        </Tag>
                      </Select.Option>
                    )
                  })
                }

              </Select>
            ),
          },
          {
            id: 'yearOfBirth',
            label: '出身年份',
            rules: [
              {
                required: false,
                message: `請選擇 出身年份`
              }
            ],
            componet: (
              <DatePicker picker='year' />
            )
          },
          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            )
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="FoodCardUserMemberEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(FoodCardUserMemberEditAndAdd)
