
import { ApiConfig, ApiConfigType } from "./api-config"
import * as Types from "./api-type"
import * as Parameters from './api-parameters-type'
import * as Res from './api-res-type'
import { AxiosInstance } from 'axios'
import { createHttp } from './http';
import config from "../config"

export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor(config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }

  async PostAuthPasswordLogin(params: Parameters.PostAuthPasswordLoginParameters): Promise<Types.PostAuthPasswordLoginResult> {
    try {
      const res = await this.http.post(`/v2/admin/auth/password/login`, params)
      const data: Res.PostAuthPasswordLoginRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostAuthSignupFormLogin(params: Parameters.PostAuthSignupFormLoginParameters): Promise<Types.PostAuthSignupFormLoginResult> {
    try {
      const res = await this.http.post(`/v2/admin/auth/signup-form/login`, params)
      const data: Res.PostAuthSignupFormLoginRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostAuthPasswordChange(params: Parameters.PostAuthPasswordChangeParameters): Promise<Types.PostAuthPasswordChangeResult> {
    try {
      const res = await this.http.post(`/v2/admin/auth/password/change`, params)
      const data: Res.PostAuthPasswordChangeRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async DeleteAuth(): Promise<void> {
    await this.http.delete(`/v2/admin/auth`)
    return
  }

  async GetUserList(params: Parameters.GetUserListParameters): Promise<Types.GetUserListResult> {
    try {
      const res = await this.http.get(`/v2/admin/user`, { params })
      const data: Res.GetUserListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetUser(params: Parameters.GetUserParameters): Promise<Types.GetUserResult> {
    try {
      const res = await this.http.get(`/v2/admin/user/${params.id}`, { params })
      const data: Res.GetUserRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostUser(params: Parameters.PostUserParameters): Promise<Types.PostUserResult> {
    try {
      const res = await this.http.post(`/v2/admin/user`, params)
      const data: Res.PostUserRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutUser(params: Parameters.PutUserParameters): Promise<Types.PutUserResult> {
    try {
      const res = await this.http.put(`/v2/admin/user/${params.id}`, params)
      const data: Res.PutUserRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteUser(params: Parameters.DeleteUserParameters): Promise<Types.DeleteUserResult> {
    try {
      const res = await this.http.get(`/v2/admin/user/${params.id}/${params.id}`, { params })
      const data: Res.DeleteUserRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetAreaList(params: Parameters.GetAreaListParameters): Promise<Types.GetAreaListResult> {
    try {
      const res = await this.http.get(`/v2/admin/area`, { params })
      const data: Res.GetAreaListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetArea(params: Parameters.GetAreaParameters): Promise<Types.GetAreaResult> {
    try {
      const res = await this.http.get(`/v2/admin/area/${params.id}`, { params })
      const data: Res.GetAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostArea(params: Parameters.PostAreaParameters): Promise<Types.PostAreaResult> {
    try {
      const res = await this.http.post(`/v2/admin/area`, params)
      const data: Res.PostAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutArea(params: Parameters.PutAreaParameters): Promise<Types.PutAreaResult> {
    try {
      const res = await this.http.put(`/v2/admin/area/${params.id}`, params)
      const data: Res.PutAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteArea(params: Parameters.DeleteAreaParameters): Promise<Types.DeleteAreaResult> {
    try {
      const res = await this.http.delete(`/v2/admin/area/${params.id}`, { params })
      const data: Res.DeleteAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetCommunityList(params: Parameters.GetCommunityListParameters): Promise<Types.GetCommunityListResult> {
    try {
      const res = await this.http.get(`/v2/admin/community`, { params })
      const data: Res.GetCommunityListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetCommunity(params: Parameters.GetCommunityParameters): Promise<Types.GetCommunityResult> {
    try {
      const res = await this.http.get(`/v2/admin/community/${params.id}`, { params })
      const data: Res.GetCommunityRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostCommunity(params: Parameters.PostCommunityParameters): Promise<Types.PostCommunityResult> {
    try {
      const res = await this.http.post(`/v2/admin/community`, params)
      const data: Res.PostCommunityRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutCommunity(params: Parameters.PutCommunityParameters): Promise<Types.PutCommunityResult> {
    try {
      const res = await this.http.put(`/v2/admin/community/${params.id}`, params)
      const data: Res.PutCommunityRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteCommunity(params: Parameters.DeleteCommunityParameters): Promise<Types.DeleteCommunityResult> {
    try {
      const res = await this.http.delete(`/v2/admin/community/${params.id}`, { params })
      const data: Res.DeleteCommunityRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetKioskVendingList(params: Parameters.GetKioskVendingListParameters): Promise<Types.GetKioskVendingListResult> {
    try {
      const res = await this.http.get(`/v2/admin/kiosk-vending`, { params })
      const data: Res.GetKioskVendingListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetKioskVending(params: Parameters.GetKioskVendingParameters): Promise<Types.GetKioskVendingResult> {
    try {
      const res = await this.http.get(`/v2/admin/kiosk-vending/${params.id}`, { params })
      const data: Res.GetKioskVendingRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostKioskVending(params: Parameters.PostKioskVendingParameters): Promise<Types.PostKioskVendingResult> {
    try {
      const res = await this.http.post(`/v2/admin/kiosk-vending`, params)
      const data: Res.PostKioskVendingRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutKioskVending(params: Parameters.PutKioskVendingParameters): Promise<Types.PutKioskVendingResult> {
    try {
      const res = await this.http.put(`/v2/admin/kiosk-vending/${params.id}`, params)
      const data: Res.PutKioskVendingRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetPartnerCompanyList(params: Parameters.GetPartnerCompanyListParameters): Promise<Types.GetPartnerCompanyListResult> {
    try {
      const res = await this.http.get(`/v2/admin/partner-company`, { params })
      const data: Res.GetPartnerCompanyListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetPartnerCompany(params: Parameters.GetPartnerCompanyParameters): Promise<Types.GetPartnerCompanyResult> {
    try {
      const res = await this.http.get(`/v2/admin/partner-company/${params.id}`, { params })
      const data: Res.GetPartnerCompanyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostPartnerCompany(params: Parameters.PostPartnerCompanyParameters): Promise<Types.PostPartnerCompanyResult> {
    try {
      const res = await this.http.post(`/v2/admin/partner-company`, params)
      const data: Res.PostPartnerCompanyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutPartnerCompany(params: Parameters.PutPartnerCompanyParameters): Promise<Types.PutPartnerCompanyResult> {
    try {
      const res = await this.http.put(`/v2/admin/partner-company/${params.id}`, params)
      const data: Res.PutPartnerCompanyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeletePartnerCompany(params: Parameters.DeletePartnerCompanyParameters): Promise<Types.DeletePartnerCompanyResult> {
    try {
      const res = await this.http.delete(`/v2/admin/partner-company/${params.id}`, { params })
      const data: Res.DeletePartnerCompanyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async DeleteKisokVending(params: Parameters.DeleteKisokVendingParameters): Promise<Types.DeleteKisokVendingResult> {
    try {
      const res = await this.http.delete(`/v2/admin/kiosk-vending/${params.id}`, { params })
      const data: Res.DeleteKisokVendingRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetFoodCardList(params: Parameters.GetFoodCardListParameters): Promise<Types.GetFoodCardListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card`, { params })
      const data: Res.GetFoodCardListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCard(params: Parameters.GetFoodCardParameters): Promise<Types.GetFoodCardResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card/${params.id}`, { params })
      const data: Res.GetFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetFoodCardQuota(params: Parameters.GetFoodCardQuotaParameters): Promise<Types.GetFoodCardQuotaResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card/${params.id}/quota`, { params })
      const data: Res.GetFoodCardQuotaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardXlsxPreview(params: Parameters.PostFoodCardXlsxPreviewParameters): Promise<Types.PostFoodCardXlsxPreviewResult> {
    try {
      const { file, ...body } = params
      const newBody: any = body
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')

      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.post(`/v2/admin/food-card/xlsx-preview?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PostFoodCardXlsxPreviewRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardData(params: Parameters.PostFoodCardDataParameters): Promise<Types.PostFoodCardDataResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card/data`, params)
      const data: Res.PostFoodCardDataRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardFamily(params: Parameters.PostFoodCardFamilyParameters): Promise<Types.PostFoodCardFamilyResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card/family`, params)
      const data: Res.PostFoodCardFamilyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardFamilyMember(params: Parameters.PostFoodCardFamilyMemberParameters): Promise<Types.PostFoodCardFamilyMemberResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card/family-member`, params)
      const data: Res.PostFoodCardFamilyMemberRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardCeateImage(): Promise<Types.PostFoodCardCeateImageResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card/create-image`)
      const data: Res.PostFoodCardCeateImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardDownloadImage(params: Parameters.PostFoodCardDownloadImageParameters): Promise<Types.PostFoodCardDownloadImageResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card/download-image`, params)
      const data: Res.PostFoodCardDownloadImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardDownloadImageSingle(params: Parameters.PostFoodCardDownloadImageSingleParameters): Promise<Types.PostFoodCardDownloadImageSingleResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card/download-image-single`, params)
      const data: Res.PostFoodCardDownloadImageSingleRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PutFoodCard(params: Parameters.PutFoodCardParameters): Promise<Types.PutFoodCardResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-card/${params.id}`, params)
      const data: Res.PutFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodCard(params: Parameters.DeleteFoodCardParameters): Promise<Types.DeleteFoodCardResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-card/${params.id}`, { params })
      const data: Res.DeleteFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetFoodCardGroupList(params: Parameters.GetFoodCardGroupListParameters): Promise<Types.GetFoodCardGroupListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-group`, { params })
      const data: Res.GetFoodCardGroupListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCardGroup(params: Parameters.GetFoodCardGroupParameters): Promise<Types.GetFoodCardGroupResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-group/${params.id}`, { params })
      const data: Res.GetFoodCardGroupRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardGroupXlsxPreview(params: Parameters.PostFoodCardGroupXlsxPreviewParameters): Promise<Types.PostFoodCardGroupXlsxPreviewResult> {
    try {
      const { file, ...body } = params
      const newBody: any = body
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')

      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.post(`/v2/admin/food-card-group/xlsx-preview?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PostFoodCardGroupXlsxPreviewRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardGroupData(params: Parameters.PostFoodCardGroupDataParameters): Promise<Types.PostFoodCardGroupDataResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-group/data`, params)
      const data: Res.PostFoodCardGroupDataRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostFoodCardGroup(params: Parameters.PostFoodCardGroupParameters): Promise<Types.PostFoodCardGroupResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-group`, params)
      const data: Res.PostFoodCardGroupRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutFoodCardGroup(params: Parameters.PutFoodCardGroupParameters): Promise<Types.PutFoodCardGroupResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-card-group/${params.id}`, params)
      const data: Res.PutFoodCardGroupRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodCardGroup(params: Parameters.DeleteFoodCardGroupParameters): Promise<Types.DeleteFoodCardGroupResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-card-group/${params.id}`, { params })
      const data: Res.DeleteFoodCardGroupRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetFoodCardRecordList(params: Parameters.GetFoodCardRecordListParameters): Promise<Types.GetFoodCardRecordListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-record`, { params })
      const data: Res.GetFoodCardRecordListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCardRecord(params: Parameters.GetFoodCardRecordParameters): Promise<Types.GetFoodCardRecordResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-record/${params.id}`, { params })
      const data: Res.GetFoodCardRecordRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutFoodCardRecord(params: Parameters.PutFoodCardRecordParameters): Promise<Types.PutFoodCardRecordResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-card-record/${params.id}`, params)
      const data: Res.PutFoodCardRecordRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodCardRecord(params: Parameters.DeleteFoodCardRecordParameters): Promise<Types.DeleteFoodCardRecordResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-card-record/${params.id}`, { params })
      const data: Res.DeleteFoodCardRecordRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  async GetStatisticKioskVendingSellWeek(params: Parameters.GetStatisticKioskVendingSellWeekParameters): Promise<Types.GetStatisticKioskVendingSellWeekResult> {
    try {
      const res = await this.http.get(`/v2/admin/statistic/kiosk-vending-sell/week`, { params })
      const data: Res.GetStatisticKioskVendingSellWeekRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetStatisticKioskVendingSellDay(params: Parameters.GetStatisticKioskVendingSellDayParameters): Promise<Types.GetStatisticKioskVendingSellDayResult> {
    try {
      const res = await this.http.get(`/v2/admin/statistic/kiosk-vending-sell/day`, { params })
      const data: Res.GetStatisticKioskVendingSellDayRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetStatisticKioskVendingMemberMonth(params: Parameters.GetStatisticKioskVendingMemberMonthParameters): Promise<Types.GetStatisticKioskVendingMemberMonthResult> {
    try {
      const res = await this.http.get(`/v2/admin/statistic/kiosk-vending-member/month`, { params })
      const data: Res.GetStatisticKioskVendingMemberMonthRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetStatisticKioskVendingMemberMonthStats(params: Parameters.GetStatisticKioskVendingMemberMonthStatsParameters): Promise<Types.GetStatisticKioskVendingMemberMonthStatsResult> {
    try {
      const res = await this.http.get(`/v2/admin/statistic/kiosk-vending-member/month-stats/${params.kioskVendingId}`, { params })
      const data: Res.GetStatisticKioskVendingMemberMonthStatsRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }





  async GetFoodCardUserMemberFamilyAreaList(params: Parameters.GetFoodCardUserMemberFamilyAreaListParameters): Promise<Types.GetFoodCardUserMemberFamilyAreaListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member-family-area`, { params })
      const data: Res.GetFoodCardUserMemberFamilyAreaListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCardUserMemberFamilyArea(params: Parameters.GetFoodCardUserMemberFamilyAreaParameters): Promise<Types.GetFoodCardUserMemberFamilyAreaResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member-family-area/${params.id}`, { params })
      const data: Res.GetFoodCardUserMemberFamilyAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardUserMemberFamilyArea(params: Parameters.PostFoodCardUserMemberFamilyAreaParameters): Promise<Types.PostFoodCardUserMemberFamilyAreaResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member-family-area`, params)
      const data: Res.PostFoodCardUserMemberFamilyAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutFoodCardUserMemberFamilyArea(params: Parameters.PutFoodCardUserMemberFamilyAreaParameters): Promise<Types.PutFoodCardUserMemberFamilyAreaResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-card-user-member-family-area/${params.id}`, params)
      const data: Res.PutFoodCardUserMemberFamilyAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodCardUserMemberFamilyArea(params: Parameters.DeleteFoodCardUserMemberFamilyAreaParameters): Promise<Types.DeleteFoodCardUserMemberFamilyAreaResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-card-user-member-family-area/${params.id}`, { params })
      const data: Res.DeleteFoodCardUserMemberFamilyAreaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }









  async GetFoodCardUserMemberFamilyOldList(params: Parameters.GetFoodCardUserMemberFamilyOldListParameters): Promise<Types.GetFoodCardUserMemberFamilyOldListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member-family-old`, { params })
      const data: Res.GetFoodCardUserMemberFamilyOldListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCardUserMemberFamilyOld(params: Parameters.GetFoodCardUserMemberFamilyOldParameters): Promise<Types.GetFoodCardUserMemberFamilyOldResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member-family-old/${params.id}`, { params })
      const data: Res.GetFoodCardUserMemberFamilyOldRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

















  async GetFoodCardUserMemberFamilyList(params: Parameters.GetFoodCardUserMemberFamilyListParameters): Promise<Types.GetFoodCardUserMemberFamilyListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member-family`, { params })
      const data: Res.GetFoodCardUserMemberFamilyListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCardUserMemberFamily(params: Parameters.GetFoodCardUserMemberFamilyParameters): Promise<Types.GetFoodCardUserMemberFamilyResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member-family/${params.id}`, { params })
      const data: Res.GetFoodCardUserMemberFamilyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardUserMemberFamily(params: Parameters.PostFoodCardUserMemberFamilyParameters): Promise<Types.PostFoodCardUserMemberFamilyResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member-family`, params)
      const data: Res.PostFoodCardUserMemberFamilyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardUserMemberFamilyChangeCode(params: Parameters.PostFoodCardUserMemberFamilyChangeCodeParameters): Promise<Types.PostFoodCardUserMemberFamilyChangeCodeResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member-family/change-code`, params)
      const data: Res.PostFoodCardUserMemberFamilyChangeCodeRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostFoodCardUserMemberFamilyChangePassword(params: Parameters.PostFoodCardUserMemberFamilyChangePasswordParameters): Promise<Types.PostFoodCardUserMemberFamilyChangePasswordResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member-family/change-password`, params)
      const data: Res.PostFoodCardUserMemberFamilyChangePasswordRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutFoodCardUserMemberFamily(params: Parameters.PutFoodCardUserMemberFamilyParameters): Promise<Types.PutFoodCardUserMemberFamilyResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-card-user-member-family/${params.id}`, params)
      const data: Res.PutFoodCardUserMemberFamilyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodCardUserMemberFamily(params: Parameters.DeleteFoodCardUserMemberFamilyParameters): Promise<Types.DeleteFoodCardUserMemberFamilyResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-card-user-member-family/${params.id}`, { params })
      const data: Res.DeleteFoodCardUserMemberFamilyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }







  async GetFoodCardUserMemberList(params: Parameters.GetFoodCardUserMemberListParameters): Promise<Types.GetFoodCardUserMemberListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member`, { params })
      const data: Res.GetFoodCardUserMemberListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodCardUserMember(params: Parameters.GetFoodCardUserMemberParameters): Promise<Types.GetFoodCardUserMemberResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-card-user-member/${params.id}`, { params })
      const data: Res.GetFoodCardUserMemberRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardUserMember(params: Parameters.PostFoodCardUserMemberParameters): Promise<Types.PostFoodCardUserMemberResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member`, params)
      const data: Res.PostFoodCardUserMemberRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodCardUserMemberDownloadXlsx(): Promise<Types.PostFoodCardUserMemberDownloadXlsxResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member/download-xlsx`)
      const data: Res.PostFoodCardUserMemberDownloadXlsxRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutFoodCardUserMember(params: Parameters.PutFoodCardUserMemberParameters): Promise<Types.PutFoodCardUserMemberResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-card-user-member/${params.id}`, params)
      const data: Res.PutFoodCardUserMemberRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodCardUserMember(params: Parameters.DeleteFoodCardUserMemberParameters): Promise<Types.DeleteFoodCardUserMemberResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-card-user-member/${params.id}`, { params })
      const data: Res.DeleteFoodCardUserMemberRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }









  async GetFoodMenuList(params: Parameters.GetFoodMenuListParameters): Promise<Types.GetFoodMenuListResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-menu`, { params })
      const data: Res.GetFoodMenuListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFoodMenu(params: Parameters.GetFoodMenuParameters): Promise<Types.GetFoodMenuResult> {
    try {
      const res = await this.http.get(`/v2/admin/food-menu/${params.id}`, { params })
      const data: Res.GetFoodMenuRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostFoodMenu(params: Parameters.PostFoodMenuParameters): Promise<Types.PostFoodMenuResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-menu`, params)
      const data: Res.PostFoodMenuRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutFoodMenu(params: Parameters.PutFoodMenuParameters): Promise<Types.PutFoodMenuResult> {
    try {
      const res = await this.http.put(`/v2/admin/food-menu/${params.id}`, params)
      const data: Res.PutFoodMenuRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteFoodMenu(params: Parameters.DeleteFoodMenuParameters): Promise<Types.DeleteFoodMenuResult> {
    try {
      const res = await this.http.delete(`/v2/admin/food-menu/${params.id}`, { params })
      const data: Res.DeleteFoodMenuRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }





  async GetSurveyList(params: Parameters.GetSurveyListParameters): Promise<Types.GetSurveyListResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey`, { params })
      const data: Res.GetSurveyListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetSurvey(params: Parameters.GetSurveyParameters): Promise<Types.GetSurveyResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey/${params.id}`, { params })
      const data: Res.GetSurveyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSurvey(params: Parameters.PostSurveyParameters): Promise<Types.PostSurveyResult> {
    try {
      const res = await this.http.post(`/v2/admin/survey`, params)
      const data: Res.PostSurveyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSurveySetUser(params: Parameters.PostSurveySetUserParameters): Promise<Types.PostSurveySetUserResult> {
    try {
      const res = await this.http.post(`/v2/admin/survey/set-user`, params)
      const data: Res.PostSurveySetUserRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutSurvey(params: Parameters.PutSurveyParameters): Promise<Types.PutSurveyResult> {
    try {
      const res = await this.http.put(`/v2/admin/survey/${params.id}`, params)
      const data: Res.PutSurveyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteSurvey(params: Parameters.DeleteSurveyParameters): Promise<Types.DeleteSurveyResult> {
    try {
      const res = await this.http.delete(`/v2/admin/survey/${params.id}`, { params })
      const data: Res.DeleteSurveyRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  async GetSurveySubjectList(params: Parameters.GetSurveySubjectListParameters): Promise<Types.GetSurveySubjectListResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-subject`, { params })
      const data: Res.GetSurveySubjectListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetSurveySubject(params: Parameters.GetSurveySubjectParameters): Promise<Types.GetSurveySubjectResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-subject/${params.id}`, { params })
      const data: Res.GetSurveySubjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSurveySubject(params: Parameters.PostSurveySubjectParameters): Promise<Types.PostSurveySubjectResult> {
    try {
      const res = await this.http.post(`/v2/admin/survey-subject`, params)
      const data: Res.PostSurveySubjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutSurveySubject(params: Parameters.PutSurveySubjectParameters): Promise<Types.PutSurveySubjectResult> {
    try {
      const res = await this.http.put(`/v2/admin/survey-subject/${params.id}`, params)
      const data: Res.PutSurveySubjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteSurveySubject(params: Parameters.DeleteSurveySubjectParameters): Promise<Types.DeleteSurveySubjectResult> {
    try {
      const res = await this.http.delete(`/v2/admin/survey-subject/${params.id}`, { params })
      const data: Res.DeleteSurveySubjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  async GetSurveySubjectAnswerList(params: Parameters.GetSurveySubjectAnswerListParameters): Promise<Types.GetSurveySubjectAnswerListResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-subject-answer`, { params })
      const data: Res.GetSurveySubjectAnswerListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetSurveySubjectAnswer(params: Parameters.GetSurveySubjectAnswerParameters): Promise<Types.GetSurveySubjectAnswerResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-subject-answer/${params.id}`, { params })
      const data: Res.GetSurveySubjectAnswerRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSurveySubjectAnswer(params: Parameters.PostSurveySubjectAnswerParameters): Promise<Types.PostSurveySubjectAnswerResult> {
    try {
      const res = await this.http.post(`/v2/admin/survey-subject-answer`, params)
      const data: Res.PostSurveySubjectAnswerRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutSurveySubjectAnswer(params: Parameters.PutSurveySubjectAnswerParameters): Promise<Types.PutSurveySubjectAnswerResult> {
    try {
      const res = await this.http.put(`/v2/admin/survey-subject-answer/${params.id}`, params)
      const data: Res.PutSurveySubjectAnswerRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteSurveySubjectAnswer(params: Parameters.DeleteSurveySubjectAnswerParameters): Promise<Types.DeleteSurveySubjectAnswerResult> {
    try {
      const res = await this.http.delete(`/v2/admin/survey-subject-answer/${params.id}`, { params })
      const data: Res.DeleteSurveySubjectAnswerRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }




  async GetSurveyResponseList(params: Parameters.GetSurveyResponseListParameters): Promise<Types.GetSurveyResponseListResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-response`, { params })
      const data: Res.GetSurveyResponseListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetSurveyResponse(params: Parameters.GetSurveyResponseParameters): Promise<Types.GetSurveyResponseResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-response/${params.id}`, { params })
      const data: Res.GetSurveyResponseRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetSurveySubjectAnswerResponseList(params: Parameters.GetSurveySubjectAnswerResponseListParameters): Promise<Types.GetSurveySubjectAnswerResponseListResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-subject-answer-response`, { params })
      const data: Res.GetSurveySubjectAnswerResponseListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetSurveySubjectAnswerResponse(params: Parameters.GetSurveySubjectAnswerResponseParameters): Promise<Types.GetSurveySubjectAnswerResponseResult> {
    try {
      const res = await this.http.get(`/v2/admin/survey-subject-answer-response/${params.id}`, { params })
      const data: Res.GetSurveySubjectAnswerResponseRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostReplenishRequest(params: Parameters.PostReplenishRequestParameters): Promise<Types.PostReplenishRequestResult> {
    try {
      const { file, ...body } = params
      const newBody: any = body
      console.dir(newBody)
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')

      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.post(`/v2/admin/replenish-request?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PostReplenishRequestRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorMessage = '' } = data
      return { kind: errorMessage }
    }
  }





  async GetImageList(params: Parameters.GetImageListParameters): Promise<Types.GetImageListResult> {
    try {
      const res = await this.http.get(`/v2/admin/image`, { params })
      const data: Res.GetImageListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetImage(params: Parameters.GetImageParameters): Promise<Types.GetImageResult> {
    try {
      const res = await this.http.get(`/v2/admin/image/${params.id}`, { params })
      const data: Res.GetImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostImage(params: Parameters.PostImageParameters): Promise<Types.PostImageResult> {
    try {

      const { file, ...body } = params
      const newBody: any = body
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')

      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.post(`/v2/admin/image?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PostImageRes = res.data

      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutImage(params: Parameters.PutImageParameters): Promise<Types.PutImageResult> {
    try {
      const { file, ...body } = params
      const newBody: any = body
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')
      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.put(`/v2/admin/image/${params.id}?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PutImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteImage(params: Parameters.DeleteImageParameters): Promise<Types.DeleteImageResult> {
    try {
      const res = await this.http.delete(`/v2/admin/image/${params.id}`, { params })
      const data: Res.DeleteImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetLabelList(params: Parameters.GetLabelListParameters): Promise<Types.GetLabelListResult> {
    try {
      const res = await this.http.get(`/v2/admin/label`, { params })
      const data: Res.GetLabelListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetLabel(params: Parameters.GetLabelParameters): Promise<Types.GetLabelResult> {
    try {
      const res = await this.http.get(`/v2/admin/label/${params.id}`, { params })
      const data: Res.GetLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostLabel(params: Parameters.PostLabelParameters): Promise<Types.PostLabelResult> {
    try {
      const res = await this.http.post(`/v2/admin/label`, params)
      const data: Res.PostLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutLabel(params: Parameters.PutLabelParameters): Promise<Types.PutLabelResult> {
    try {
      const res = await this.http.put(`/v2/admin/label/${params.id}`, params)
      const data: Res.PutLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteLabel(params: Parameters.DeleteLabelParameters): Promise<Types.DeleteLabelResult> {
    try {
      const res = await this.http.delete(`/v2/admin/label/${params.id}`, { params })
      const data: Res.DeleteLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }






  async GetProductList(params: Parameters.GetProductListParameters): Promise<Types.GetProductListResult> {
    try {
      const res = await this.http.get(`/v2/admin/product`, { params })
      const data: Res.GetProductListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetProduct(params: Parameters.GetProductParameters): Promise<Types.GetProductResult> {
    try {
      const res = await this.http.get(`/v2/admin/product/${params.id}`, { params })
      const data: Res.GetProductRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostProduct(params: Parameters.PostProductParameters): Promise<Types.PostProductResult> {
    try {
      const res = await this.http.post(`/v2/admin/product`, params)
      const data: Res.PostProductRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutProduct(params: Parameters.PutProductParameters): Promise<Types.PutProductResult> {
    try {
      const res = await this.http.put(`/v2/admin/product/${params.id}`, params)
      const data: Res.PutProductRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteProduct(params: Parameters.DeleteProductParameters): Promise<Types.DeleteProductResult> {
    try {
      const res = await this.http.delete(`/v2/admin/product/${params.id}`, { params })
      const data: Res.DeleteProductRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }







  async GetProductLabelList(params: Parameters.GetProductLabelListParameters): Promise<Types.GetProductLabelListResult> {
    try {
      const res = await this.http.get(`/v2/admin/product-label`, { params })
      const data: Res.GetProductLabelListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetProductLabel(params: Parameters.GetProductLabelParameters): Promise<Types.GetProductLabelResult> {
    try {
      const res = await this.http.get(`/v2/admin/product-label/${params.id}`, { params })
      const data: Res.GetProductLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostProductLabel(params: Parameters.PostProductLabelParameters): Promise<Types.PostProductLabelResult> {
    try {
      const res = await this.http.post(`/v2/admin/product-label`, params)
      const data: Res.PostProductLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async DeleteProductLabel(params: Parameters.DeleteProductLabelParameters): Promise<Types.DeleteProductLabelResult> {
    try {
      const res = await this.http.delete(`/v2/admin/product-label/${params.id}`, { params })
      const data: Res.DeleteProductLabelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }






  async GetHealthTipList(params: Parameters.GetHealthTipListParameters): Promise<Types.GetHealthTipListResult> {
    try {
      const res = await this.http.get(`/v2/admin/health-tip`, { params })
      const data: Res.GetHealthTipListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetHealthTip(params: Parameters.GetHealthTipParameters): Promise<Types.GetHealthTipResult> {
    try {
      const res = await this.http.get(`/v2/admin/health-tip/${params.id}`, { params })
      const data: Res.GetHealthTipRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostHealthTip(params: Parameters.PostHealthTipParameters): Promise<Types.PostHealthTipResult> {
    try {
      const res = await this.http.post(`/v2/admin/health-tip`, params)
      const data: Res.PostHealthTipRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutHealthTip(params: Parameters.PutHealthTipParameters): Promise<Types.PutHealthTipResult> {
    try {
      const res = await this.http.put(`/v2/admin/health-tip/${params.id}`, params)
      const data: Res.PutHealthTipRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteHealthTip(params: Parameters.DeleteHealthTipParameters): Promise<Types.DeleteHealthTipResult> {
    try {
      const res = await this.http.delete(`/v2/admin/health-tip/${params.id}`, { params })
      const data: Res.DeleteHealthTipRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetReplenishRequestList(params: Parameters.GetReplenishRequestListParameters): Promise<Types.GetReplenishRequestListResult> {
    try {
      const res = await this.http.get(`/v2/admin/replenish-request`, { params })
      const data: Res.GetReplenishRequestListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetReplenishRequestDetail(params: Parameters.GetReplenishRequestDetailParameters): Promise<Types.GetReplenishRequestDetailResult> {
    try {
      const res = await this.http.get(`/v2/admin/replenish-request/detail`, { params })
      const data: Res.GetReplenishRequestDetailRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  async PostReplenishRequestCancel(params: Parameters.GetReplenishRequestCancelParameters): Promise<Types.GetReplenishRequestCancelResult> {
    try {
      const res = await this.http.post(`/v2/admin/replenish-request/cancel`, params)
      const data: Res.GetReplenishRequestCancelRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }






  async GetMessageList(params: Parameters.GetMessageListParameters): Promise<Types.GetMessageListResult> {
    try {
      const res = await this.http.get(`/v2/admin/message`, { params })
      const data: Res.GetMessageListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetMessage(params: Parameters.GetMessageParameters): Promise<Types.GetMessageResult> {
    try {
      const res = await this.http.get(`/v2/admin/message/${params.id}`, { params })
      const data: Res.GetMessageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostMessage(params: Parameters.PostMessageParameters): Promise<Types.PostMessageResult> {
    try {
      const res = await this.http.post(`/v2/admin/message`, params)
      const data: Res.PostMessageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutMessage(params: Parameters.PutMessageParameters): Promise<Types.PutMessageResult> {
    try {
      const res = await this.http.put(`/v2/admin/message/${params.id}`, params)
      const data: Res.PutMessageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteMessage(params: Parameters.DeleteMessageParameters): Promise<Types.DeleteMessageResult> {
    try {
      const res = await this.http.delete(`/v2/admin/message/${params.id}`, { params })
      const data: Res.DeleteMessageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostMessagePush(params: Parameters.PostMessagePushParameters): Promise<Types.PostMessagePushResult> {
    try {
      const res = await this.http.post(`/v2/admin/message/push`, params)
      const data: Res.PostMessagePushRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }




  async GetMessageRecordList(params: Parameters.GetMessageRecordListParameters): Promise<Types.GetMessageRecordListResult> {
    try {
      const res = await this.http.get(`/v2/admin/message-record`, { params })
      const data: Res.GetMessageRecordListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetMessageRecord(params: Parameters.GetMessageRecordParameters): Promise<Types.GetMessageRecordResult> {
    try {
      const res = await this.http.get(`/v2/admin/message-record/${params.id}`, { params })
      const data: Res.GetMessageRecordRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async DeleteMessageRecord(params: Parameters.DeleteMessageRecordParameters): Promise<Types.DeleteMessageRecordResult> {
    try {
      const res = await this.http.delete(`/v2/admin/message-record/${params.id}`, { params })
      const data: Res.DeleteMessageRecordRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostFoodCardUserMemberFamilyExcelView(params: Parameters.PostFoodCardUserMemberFamilyExcelViewParameters): Promise<Types.PostFoodCardUserMemberFamilyExcelViewResult> {
    try {
      const { file, ...body } = params
      const newBody: any = body
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')

      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.post(`/v2/admin/food-card-user-member-family/excel-view?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PostFoodCardUserMemberFamilyExcelViewRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostFoodCardUserMemberFamilySignupForm(params: Parameters.PostFoodCardUserMemberFamilySignupFormParameters): Promise<Types.PostFoodCardUserMemberFamilySignupFormResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member-family/${params.id}/signup-form`, params)
      const data: Res.PostFoodCardUserMemberFamilySignupFormRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostFoodCardUserMemberFamilyRenewal(params: Parameters.PostFoodCardUserMemberFamilyRenewalParameters): Promise<Types.PostFoodCardUserMemberFamilyRenewalResult> {
    try {
      const res = await this.http.post(`/v2/admin/food-card-user-member-family/${params.id}/renewal`, params)
      const data: Res.PostFoodCardUserMemberFamilyRenewalRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetSignupFormList(params: Parameters.GetSignupFormListParameters): Promise<Types.GetSignupFormListResult> {
    try {
      const res = await this.http.get(`/v2/admin/signup-form`, { params })
      const data: Res.GetSignupFormListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async GetSignupForm(params: Parameters.GetSignupFormParameters): Promise<Types.GetSignupFormResult> {
    try {
      const res = await this.http.get(`/v2/admin/signup-form/${params.id}`, { params })
      const data: Res.GetSignupFormRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PutSignupForm(params: Parameters.PutSignupFormParameters): Promise<Types.PutSignupFormResult> {
    try {
      const res = await this.http.put(`/v2/admin/signup-form/${params.id}`, params)
      const data: Res.PutSignupFormRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSignupFormResendExpiredSms(params: Parameters.PostSignupFormResendExpiredSmsParameters): Promise<Types.PostSignupFormResendExpiredSmsResult> {
    try {
      const res = await this.http.post(`/v2/admin/signup-form/${params.id}/resend-expired-sms`, params)
      const data: Res.PostSignupFormResendExpiredSmsRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutSignupFormData(params: Parameters.PutSignupFormDataParameters): Promise<Types.PutSignupFormDataResult> {
    try {
      const res = await this.http.put(`/v2/admin/signup-form/${params.id}/data`, params)
      const data: Res.PutSignupFormDataRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostSignupFormSubmit(params: Parameters.PostSignupFormSubmitParameters): Promise<Types.PostSignupFormSubmitResult> {
    try {
      const res = await this.http.post(`/v2/admin/signup-form/${params.id}/submit`, params)
      const data: Res.PostSignupFormSubmitRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostSignupFormVerifyOctopus(params: Parameters.PostSignupFormVerifyOctopusParameters): Promise<Types.PostSignupFormVerifyOctopusResult> {
    try {
      const res = await this.http.post(`/v2/admin/signup-form/verify-octopus`, params)
      const data: Res.PostSignupFormVerifyOctopusRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PostSignupFormImage(params: Parameters.PostSignupFormImageParameters): Promise<Types.PostSignupFormImageResult> {
    try {
      const res = await this.http.post(`/v2/admin/signup-form/${params.id}/image`, params)
      const data: Res.PostSignupFormImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async GetSignupFormImage(params: Parameters.GetSignupFormImageParameters): Promise<Types.GetSignupFormImageResult> {
    try {
      const res = await this.http.get(`/v2/admin/signup-form/${params.id}/image/${params.imageId}`, { params })
      const data: Res.GetSignupFormImageRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSignupFormImageRotation(params: Parameters.PostSignupFormImageRotationParameters): Promise<Types.PostSignupFormImageRotationResult> {
    try {
      const res = await this.http.post(`/v2/admin/signup-form/${params.id}/image/${params.imageId}/rotation`, params)
      const data: Res.PostSignupFormImageRotationRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async DeleteSignupForm(params: Parameters.DeleteSignupFormParameters): Promise<Types.DeleteSignupFormResult> {
    try {
      const res = await this.http.delete(`/v2/admin/signup-form/${params.id}`, { params })
      const data: Res.DeleteSignupFormRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostSignupFormDownloadXlsx(): Promise<Types.PostSignupFormDownloadXlsxResult> {
    try {
      const res = await this.http.post(`/v2/admin/signup-form/download-xlsx`)
      const data: Res.PostSignupFormDownloadXlsxRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }







  async GetQuotaList(params: Parameters.GetQuotaListParameters): Promise<Types.GetQuotaListResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota`, { params })
      const data: Res.GetQuotaListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetQuota(params: Parameters.GetQuotaParameters): Promise<Types.GetQuotaResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota/${params.id}`, { params })
      const data: Res.GetQuotaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostQuota(params: Parameters.PostQuotaParameters): Promise<Types.PostQuotaResult> {
    try {
      const res = await this.http.post(`/v2/admin/quota`, params)
      const data: Res.PostQuotaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutQuota(params: Parameters.PutQuotaParameters): Promise<Types.PutQuotaResult> {
    try {
      const res = await this.http.put(`/v2/admin/quota/${params.id}`, params)
      const data: Res.PutQuotaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteQuota(params: Parameters.DeleteQuotaParameters): Promise<Types.DeleteQuotaResult> {
    try {
      const res = await this.http.delete(`/v2/admin/quota/${params.id}`, { params })
      const data: Res.DeleteQuotaRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }






  async GetQuotaFoodCardList(params: Parameters.GetQuotaFoodCardListParameters): Promise<Types.GetQuotaFoodCardListResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota-food-card`, { params })
      const data: Res.GetQuotaFoodCardListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetQuotaFoodCard(params: Parameters.GetQuotaFoodCardParameters): Promise<Types.GetQuotaFoodCardResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota-food-card/${params.id}`, { params })
      const data: Res.GetQuotaFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostQuotaFoodCard(params: Parameters.PostQuotaFoodCardParameters): Promise<Types.PostQuotaFoodCardResult> {
    try {
      const res = await this.http.post(`/v2/admin/quota-food-card`, params)
      const data: Res.PostQuotaFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async DeleteQuotaFoodCard(params: Parameters.DeleteQuotaFoodCardParameters): Promise<Types.DeleteQuotaFoodCardResult> {
    try {
      const res = await this.http.delete(`/v2/admin/quota-food-card/${params.id}`, { params })
      const data: Res.DeleteQuotaFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }




  async GetQuotaIntervalList(params: Parameters.GetQuotaIntervalListParameters): Promise<Types.GetQuotaIntervalListResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota-interval`, { params })
      const data: Res.GetQuotaIntervalListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetQuotaInterval(params: Parameters.GetQuotaIntervalParameters): Promise<Types.GetQuotaIntervalResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota-interval/${params.id}`, { params })
      const data: Res.GetQuotaIntervalRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostQuotaInterval(params: Parameters.PostQuotaIntervalParameters): Promise<Types.PostQuotaIntervalResult> {
    try {
      const res = await this.http.post(`/v2/admin/quota-interval`, params)
      const data: Res.PostQuotaIntervalRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutQuotaInterval(params: Parameters.PutQuotaIntervalParameters): Promise<Types.PutQuotaIntervalResult> {
    try {
      const res = await this.http.put(`/v2/admin/quota-interval/${params.id}`, params)
      const data: Res.PutQuotaIntervalRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteQuotaInterval(params: Parameters.DeleteQuotaIntervalParameters): Promise<Types.DeleteQuotaIntervalResult> {
    try {
      const res = await this.http.delete(`/v2/admin/quota-interval/${params.id}`, { params })
      const data: Res.DeleteQuotaIntervalRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }






  async GetQuotaIntervalFoodCardList(params: Parameters.GetQuotaIntervalFoodCardListParameters): Promise<Types.GetQuotaIntervalFoodCardListResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota-interval-food-card`, { params })
      const data: Res.GetQuotaIntervalFoodCardListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetQuotaIntervalFoodCard(params: Parameters.GetQuotaIntervalFoodCardParameters): Promise<Types.GetQuotaIntervalFoodCardResult> {
    try {
      const res = await this.http.get(`/v2/admin/quota-interval-food-card/${params.id}`, { params })
      const data: Res.GetQuotaIntervalFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostQuotaIntervalFoodCard(params: Parameters.PostQuotaIntervalFoodCardParameters): Promise<Types.PostQuotaIntervalFoodCardResult> {
    try {
      const res = await this.http.post(`/v2/admin/quota-interval-food-card`, params)
      const data: Res.PostQuotaIntervalFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async DeleteQuotaIntervalFoodCard(params: Parameters.DeleteQuotaIntervalFoodCardParameters): Promise<Types.DeleteQuotaIntervalFoodCardResult> {
    try {
      const res = await this.http.delete(`/v2/admin/quota-interval-food-card/${params.id}`, { params })
      const data: Res.DeleteQuotaIntervalFoodCardRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


}


